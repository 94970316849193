

.rs-box-media{
      min-height: 300px;
      padding-bottom :50px;
      width :350px!important;
      .btn{
          position : absolute;
          bottom :10px;
      }
  }