.img-loader {
    height: 100px;
}

#content-pic {
    display: flex;
    flex-direction: column;
}

#content-fold {
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

#content-fold:hover {
    background: #f3f3f3;
}

.img-min {
    height: 150px;
}

.content-medias-upload-link {
    border: 2px solid #e3e4e5;
    cursor: pointer;
    img {
        animation: animation-out 400ms linear 1 both;
        animation-direction: normal;
    }
    &:hover {
        background: #e3e4e5;
        img {
            animation: animation-in 1000ms linear 1 both;
            animation-direction: normal;
        }
    }
}

.content-medias-upload {
    border: 2px solid #e3e4e5;
    height: 150px;
    width: 240px;
    display: flex;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
}


.alert-info {
    width: 240px;
}

.content-medias-upload:hover {
    background: #e3e4e5;
}
.content-medias-upload i, .content-medias-upload img {
    animation: animation-out 400ms linear 1 both;
    animation-direction: normal;
}

.content-medias-upload:hover i, .content-medias-upload:hover img {
    animation: animation-in 1000ms linear 1 both;
    animation-direction: normal;
    /*
    transition: all 0.5s;
    transform: rotate(0.5grad) scale(1.5);
    */
}

.content-medias-icon-upload {
    margin: auto;
    transition: all 0.5s;
    /*transform: rotate(-90deg) scale(1);*/
}

.content-medias-upload-empty {
    border: solid 2px #e3e4e5;
    text-align: center;
    padding: 150px;
}

.teaser-simple {
    height: 150px;
    width: 240px;
    margin-right: 10px;
}

.content-medias-form {
    display: flex;
}

img {
    max-width: 200%;
}

#modal-image {
    text-align: center;
}

.content-pic-fold {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
}

.content-pic-fold:hover {
    background: #F5F5F5;
}

.content-pic-folders {
    display: flex;
    flex-wrap: wrap;
}

.content-pic-pictures {
    display: flex;
}

.folders-content {
    margin-bottom: 5px;
}





  
  /* Generated with Bounce.js. Edit at https://goo.gl/cG6Psp */
  
  @keyframes animation-in { 
    0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    3.2% { transform: matrix3d(0.763, 0.652, 0, 0, -0.501, 0.871, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    4.3% { transform: matrix3d(0.614, 0.797, 0, 0, -0.649, 0.768, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    6.41% { transform: matrix3d(0.325, 0.953, 0, 0, -0.853, 0.534, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    8.61% { transform: matrix3d(0.083, 1.002, 0, 0, -0.961, 0.296, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    12.71% { transform: matrix3d(-0.149, 0.992, 0, 0, -1.003, -0.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    12.91% { transform: matrix3d(-0.154, 0.991, 0, 0, -1.003, -0.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    17.22% { transform: matrix3d(-0.181, 0.984, 0, 0, -0.994, -0.111, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    18.92% { transform: matrix3d(-0.163, 0.987, 0, 0, -0.994, -0.116, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    25.23% { transform: matrix3d(-0.066, 0.998, 0, 0, -0.998, -0.067, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    28.33% { transform: matrix3d(-0.028, 1, 0, 0, -0.999, -0.037, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    31.43% { transform: matrix3d(-0.004, 1, 0, 0, -1, -0.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    39.44% { transform: matrix3d(0.013, 1, 0, 0, -1, 0.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    56.46% { transform: matrix3d(0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    61.66% { transform: matrix3d(-0.001, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    81.48% { transform: matrix3d(0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    83.98% { transform: matrix3d(0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    100% { transform: matrix3d(0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
  }
  
  @keyframes animation-out { 
    0% { transform: matrix3d(0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90% { transform: matrix3d(0.763, 0.652, 0, 0, -0.501, 0.871, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    85% { transform: matrix3d(0.614, 0.797, 0, 0, -0.649, 0.768, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    80% { transform: matrix3d(0.325, 0.953, 0, 0, -0.853, 0.534, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    75% { transform: matrix3d(0.083, 1.002, 0, 0, -0.961, 0.296, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    70% { transform: matrix3d(-0.149, 0.992, 0, 0, -1.003, -0.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    65% { transform: matrix3d(-0.154, 0.991, 0, 0, -1.003, -0.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    60% { transform: matrix3d(-0.181, 0.984, 0, 0, -0.994, -0.111, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    50% { transform: matrix3d(-0.163, 0.987, 0, 0, -0.994, -0.116, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    40% { transform: matrix3d(-0.066, 0.998, 0, 0, -0.998, -0.067, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    30% { transform: matrix3d(-0.028, 1, 0, 0, -0.999, -0.037, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    20% { transform: matrix3d(-0.004, 1, 0, 0, -1, -0.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    10% { transform: matrix3d(0.013, 1, 0, 0, -1, 0.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  }
  