#modal-medias{
  .modal-header{
    height: 54px;
  }
  .modal-body{
    height: calc(100vh - 58px) !important;
    padding: 0;

  }
}
#modal-image, #modal-crop {
  z-index: 9999;
  .modal-header {
    .close {
      padding: 0;
      font-size: 3.5em;
      color: $color-secondary;
      cursor: pointer;
    }
  }
}
#modal-image{
  .modal-body{
    .container-img{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 750px;
      height: 500px;

      img{
        width: auto;
        max-width: 100%;
        /*height: auto;*/
        max-height: 100%;
      }
    }
  }
}

//Iframe Medias


.card.card-medias{
  //height: 83%;
  height: calc(100vh - 100px);
  margin-bottom: 0;

  .card-body{
    height: calc(100% - 56px);
    //overflow-y: auto;

    #content-folder, #area{
      //overflow-y: auto;
    }

    #content-child-folder{
      ul{
        .content-medias-upload{
          margin: 5px;
          width: 250px;
          height: 160px;
        }
        .media-item{
          padding: 0.25rem;
          margin: 5px;
          border: 1px solid #f3f3f3;

          .banner-pic{
            z-index: 10;
            top:0;
            background: $color-third;
          }

          .teaser{
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 0;
            background: $gray-100;

            &.item-check{
              border: 5px solid $color-third;
            }

            img{
              width: auto;
              max-width: 100%;
              height: auto;
              min-height: inherit;
              max-height: 100%;
              padding: 0;
              border-radius: 0;
            }
            figcaption{
              background: rgba($color-secondary,0.7);
              opacity: 1;

              .valid{
                color: #dfe0e1;
              }
            }
          }
        }
      }
    }

  }



  .card-footer{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}

body.pace-done{
  .header + .main-content{
    .card.card-medias{
      height: calc(100vh - 200px)!important;
    }
  }

}