.dashboard-config{

  .donnee-num{
    img{
      max-height: 33px !important;
    }
  }
  .progress{
    border-radius: 10px;
    .progress-bar{
      height: 10px;
      border-radius: 10px;
    }
  }

  .container-blocs-contenus{
    font-size: 0;

    & > div{
      position: relative;
      display: inline-block;
      width: 25%;
      padding: 0 10px;

      &:not(:last-of-type){
        &:after{
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background: $gray-300;
        }
      }

      img{
        height: 58px;
      }
    }
  }
}