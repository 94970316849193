.kitinstall {

  @include media-down(md) {
    .btn-prev-step {
      overflow    : hidden;

      height      : 40px;
      width       : 40px;
      margin-left : 20px;
      position    : relative;
      background  : $white !important;
      border      : 0px solid $color-primary;
      transition  : all .35 ease;
      &:active {
        transition : all .35 ease;
        transform  : scale(0.8);
      }
      & > span {
        display : none;
      }
      &:before {
        font-family : "themify";
        content     : "\e629";
        font-size   : 1.5em;
        color       : $color-primary;
        position    : absolute;
        top         : 50%;
        left        : 50%;
        transform   : translate(-50%, -50%);
        width       : 40px;
      }
    }

    background-color : $white !important;
    text-align       : center !important;
    h2, h3, h4 {
      text-align : center !important;
    }
    label, .form-group {
      text-align : left !important;
    }
    .adresse_diff {
      .form-group {
        text-align : center !important;
      }
    }
  }
}

#install-main-content {
  @include media-down(md) {
    padding : 0 !important;

    & > [class="col-"], & > .col-12 {
      padding : 0 !important;
    }
  }
}

.card-trigger {
  padding         : 2rem;
  padding-top     : 145px;
  position        : relative;
  min-height      : 550px;
  display         : flex;
  justify-content : center;

  @include media-down(md) {
    padding-top : 30px;
  }

  .kitinstall-title {
    padding  : 2rem;
    position : absolute;
    top      : 0;
    width    : 100%;
    left     : 0;

    @include media-down(md) {
      position : static !important;
      padding  : 0 !important;
    }

  }

  &.welcome, &.thanks {
    padding-top : 20px;
    .kitinstall-title {
      position : static !important;
      padding  : 0 !important;

    }

    svg {
      font-family : $font-body !important;
      @include media-down(md){
        display : none;
      }
      text {
        font-family : $font-body !important;
      }
    }
  }
}

#install-form {
  width      : 100%;
  text-align : left;
}

#install-stepper {
  min-height  : 50px;
  padding-top : 1rem;

}

@include media-down(md) {
  .main-content {
    padding     : 0 !important;
    padding-top : 1rem !important;
  }
}

@include media-down(lg) {
  .no-border-lg {
    border : 0 !important;
  }
}

#install-editor {
  height : auto;
}

#install-form {

  @include media-down(md) {
    /*text-align : center!important;*/
  }

  #topbar-buttons {
    .text-center {
      text-align : right !important;
    }
  }
}

.logo-kitinstall {
  .alert-info {
    background-color : transparent;
    border           : 0;
    color            : $color-primary;
    padding-left     : 60px !important;
    position         : relative;
    font-weight      : 600;

    &:before {
      font      : normal normal normal 25px/1 FontAwesome;
      content   : "\f05a";
      position  : absolute;
      left      : 15px;
      top       : 50%;
      transform : translateY(-50%);

    }
  }
}

@include media-down(md) {
  #install-content-illustration {
    display : none;
  }
}




