

.master-row{
  background : $gray-200;
  .fa{
    transform: all 0.25 ease;
    transform: scale(1);
  }
  .fa:active{
    transform: all 0.25 ease;
    transform: scale(0.8);
  }
}

#rename-button{
  border-radius: 0 10rem 10rem 0;
}

.slave-row{
  border-bottom:1px solid $gray-100;

  &:nth-child(even){
    background: $gray-100;
  }
  .fa{
    color : $color-text-secondary!important;
  }
}