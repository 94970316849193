.card{
  &.card-donnee-num-with-form{
    & > .row{
      height: 100%;
    }

    .container-donnee-numerique{

      .donnee-num{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        background: $color-pale-primary;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;

        .illustration{
          min-height :150px;
          width :150px;
        }

        img{
          max-height: 110px;
          margin-bottom: 20px;
        }
      }
    }
    .card-body{
      height: 100%;

      form{
        height: 100%;

        .container-form{
          height: 90%;
        }
      }
    }
  }
  &.dashboard-card-height-total{
   height: 390px;
  }
  &.card-users, &.card-points{
    min-height: 330px;

    & > .row{
      flex: 1;
      min-height: 330px;
    }
  }
  &.card-points{
    .container-form{
      label{
        margin-bottom: 0;
      }
      .form-group{
        &:not(:first-of-type){
          label{
            margin-top: 10px;
          }
        }
      }
    }
  }

  &.card-planning{
    .card-body{
      display: flex;
      align-items: center;

      .nav-tabs-left{
        flex: 1;
        width: 100%;

        .nav-tabs{
          display: flex;
          flex-wrap: nowrap;
          margin: 0;
          padding: 10px 5px 10px 10px;
          width: 100%;
          height: 100%;


          li{
            a{
              white-space: normal;
              border: 0;
              padding-right: 30px !important;

              .mask{
                display: none;
              }

              .timeline-point{
                .badge-primary{
                  //background: $color-primary;
                  background: lighten($color-primary, 26%);

                }
                &:before{
                  top: 0;
                  background: $gray-300;
                }
              }
              .timeline-content{
                p{
                  color: lighten($color-primary, 26%);
                  line-height: normal;
                }
              }
              &.active{
                position: relative;
                background: $color-pale-primary;

                .mask{
                  display: inline-block;
                  position: absolute;
                  right: 0;
                  top: 0;
                }
                .timeline-point{
                  .badge-primary{
                    background: $color-primary;

                    &:after{
                      background: $color-primary;
                    }
                  }
                }

                .timeline-content{
                  p{
                    color: $color-primary;
                  }
                }
              }
            }
          }
        }
        .tab-content{
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: $color-pale-primary !important;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;

          .tab-pane{
            padding: 15px;
            text-align: center;

            .container-img{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 230px;

              img{
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

//Dashboard Communication
.card-communication{
  height: 410px;


  .container-img{
    height: 265px;

    img{
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }
}
.card-flex-1{
  flex: 1;
}
