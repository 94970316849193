.topbar{
  @media (max-width: 1200px){
    .breadcrumb{
      display : none;
    }
  }
  .dropdown-menu{
    background-color : transparent!important;
    width :350px;

    .media-list{
      width :100%!important;
    }

    &::before{
      // Petit triangle
      // width: 0;
      // height: 0;
      // border-left: 9px solid transparent;
      // border-right: 9px solid transparent;

      // border-bottom: 11px solid $color-primary;

      z-index: 50;
      top: -12px;
      width: 24px;
      height: 24px;
      border: 2px $color-pale-primary;
      border-left: 1px solid $color-primary;
      border-top: 1px solid $color-primary;
      background-color: $color-pale-primary;
      transform: rotate(45deg);

    }
    &::after{
      display : none!important;
    }

    .card{
      overflow: hidden;
      margin-bottom :0px;
      border: 1px solid $color-primary;
    }

    .card{
      .media.media-single{
       /* &:hover{
          border-right :2px solid $color-primary;
        }*/
      }
    }
  }
}
