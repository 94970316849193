.rs-box-media {
  min-height: 300px;
  padding-bottom: 50px;
  width: 350px !important;
}

.rs-box-media .btn {
  position: absolute;
  bottom: 10px;
}

#shop {
  padding-bottom: 36px;
}

.specific-modal-shop {
  overflow: hidden !important;
}

.specific-modal-shop .modal-body {
  padding: 0;
}

.shop-view {
  overflow: auto;
  height: 1000px;
}

.content-selection {
  transition: all 0.35s ease;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

.content-selection .fa {
  transition: all 0.35s ease;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.content-selection.down {
  transition: all 0.35s ease;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.content-selection.down .fa {
  transition: all 0.35s ease;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

#shop-selection .product .card-body img {
  max-width: 50%;
  display: block;
  margin: 0 auto;
}

.trigger-selection {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  width: 100%;
}

.menu-link.specific .fa, .menu-link.specific .title {
  color: #fff;
  transition: .2s linear;
}

.menu-link.specific:hover .fa, .menu-link.specific:hover .title, .menu-link.specific.active .fa, .menu-link.specific.active .title {
  color: #ebeff2;
  transition: .2s linear;
}

.cursor-pointer {
  cursor: pointer;
}

.aligner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}



/*
.sidebar.sidebar-light .menu > .menu-item:hover > .menu-link,.sidebar.sidebar-light .menu > .menu-item.active > .menu-link,.sidebar.sidebar-light .menu-submenu{
  background : #ebeff2!important;
}*/



html body [type=button]{
  -webkit-appearance: none;
}




[type=reset], [type=submit], button, html [type=button]{
  -webkit-appearance: none;
}


.hidden{
  display : none!important;
}

.no-bg{
  background: none;
}

//Topbar
.topbar-btn{
  img.aide{
    width: 50px;
  }
}


//line-height

.lh-20{
  line-height: 20px;
}

.popover.fade.bs-popover-bottom.show{
  z-index: 1;
}