
#tree{
  .list-group-item{
    display : flex;
    align-items: center;
    position : relative;
    width :100%;
    justify-content: space-between;
    & > span.icon:not(.expand-icon){
      display : none;
    }
    & > a[href=""],span.indent{
      margin-left :3rem;
    }
    padding-left :2rem;
    & > span.expand-icon{
     /* position : absolute;
      left :10px;
      top :50%;
      transform:translateY(-50%);*/
    }
    & > a[href^="#"]{
      width :100%;
    }
    .fluidlink{
      flex-basis: 0;
      flex-grow: 2;
    /*  display : none!important;*/
    }
  }

  .flex-basis{
    flex-basis: 0;
  }

}