
@mixin rs-tickstack($key,$color){

  $icon-size:1rem;
  $size:37px;

  .tickstack{
    display : flex;
    justify-content :space-around;

    &.tickstack-#{$key}{
      a{

        display : flex;
        justify-content: center;
        align-items: center;
        /////////////
        margin :0 0.1rem;
        /////////////

        transition: background-color .35s;
        background-color : $color;
        height :$size;
        width :$size;
        text-align : center;
        color : $white;
        line-height : $size;
        display : inline-block;
        font-size :$icon-size;
        i{
          font-size : $icon-size;
        }

        &:hover{
          transition: background-color .35s;
          background-color : darken($color,20%);

        }
      }
      a:first-child:nth-last-child(1) {
        /*border-radius: 50%;*/
        position : relative;
        z-index: 2;
        &:after,&:before{
          z-index: 1;
          position : absolute;
          content : '';
          display : inline-block;
          width :$size;
          height :$size;
          top :0;
          background-color : $color;
          transition: background-color .35s;
        }

        &:hover{
          &:after,&:before{
            transition: background-color .35s;
            background-color : darken($color,20%);

          }
        }

        span,i{
          position : relative;
          z-index :2;
        }

        &:before{
          left : 0;
          transform: translateX(-20%);
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;

        }
        &:after{
          right : 0;
          transform: translateX(20%);
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
        }

      }

      a:last-child{
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
      a:first-child{
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }
  }
}


@each $key, $color in $colors{
@include rs-tickstack($key,$color);
}
