.lobibox-notify-wrapper{
  &.right{
    right: 30px;
  }

  .lobibox-notify{
    font-family :$font-body!important;
    min-height: 50px;
    border-radius: 50px;
    background: $white !important;

    .lobibox-notify-icon-wrapper{
      left: inherit;
      width: 50px;
      margin-right: 10px;
      border-top-left-radius:50px;
      border-bottom-left-radius:50px;

      .lobibox-notify-icon{
        > div{
          .icon-el{
            font-size: inherit;

            .fa{
              font-size: 2em;
            }
          }
        }
      }
    }
    .lobibox-notify-body{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 0 0 60px;
      padding: 5px 0;
      height: 93%;
      width: 75%;
      line-height: 18px;

      .lobibox-notify-title{
        margin-bottom: 5px;
        font-size: 1.2em;
        font-weight: 700;
      }
    }
    .lobibox-close{
      top: 50%;
      transform: translateY(-50%);
      font-weight: 700;
    }
    .lobibox-delay-indicator{
      display: none;
      width: 333px;
      left: inherit;
      right: 15px;
    }
  }
}

@mixin rs-lobibox($name,$color){

  .lobibox-notify{
    &.lobibox-notify-#{$name}{
      border: 1px solid $color;
      color: $color;

      .lobibox-notify-icon-wrapper{
        background: $color;
        color: $white;
      }
      .lobibox-delay-indicator{
        & > div{
          background: $color;
        }
      }
      .lobibox-close{
        &:hover{
          background: $color;
          color: $white;
        }
      }
    }
  }
}

@include rs-lobibox(success,$color-green);
@include rs-lobibox(error,$color-red);
@include rs-lobibox(info,$color-yellow);
@include rs-lobibox(warning,$color-blue);