//fix bug safari z-index + hidden
.lity-content{
  transform: initial!important;
}

.popin-changelog{

  border-radius: 10px;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial;

  strong{
    font-weight: 700;
  }
  .swiper-container{
    overflow: inherit;

    .swiper-slide{
      border-radius: 10px;
      opacity: 0;

      &.swiper-slide-active{
        opacity: 1;
        overflow: hidden;
      }



      .card{
        margin-bottom: 0;
        text-align: center;

        .card-title{
          height: 100px;
          background: $color-pale-primary;

          span{
            font-size: 1.8em;
            color:$color-primary;
          }
        }

        .card-footer{
          padding : 20px;
        }
        .card-body{
          padding-bottom: 0;
          position : relative;

          // global svg

          canvas {
            display: block;
            width: 100%;
            visibility: hidden;
          }

          svg{
            width :100%;
            display : block;
          }

          svg{
            &#etape_2{
              .st0{fill:#E7ECF0;}
              .st1{fill:#FFFFFF;}
              .st2{fill:#354351;}
              .st3{fill:#FFBC2A;}
              .st4{fill:#DBDBDC;}
              .st5{fill:#8A7542;}
              .st6{fill:#899097;}
              .st7{fill:#4B5864;}
              .st8{fill:#697A82;}
              .st9{fill:#425158;}
              .st10{fill:none;}
              .st11{font-family:'OpenSans-Semibold';}
              .st12{font-size:12px;}
              .st13{font-size:11px;}
              .st14{fill:none;stroke:#808080;stroke-miterlimit:10;}
              .st15{fill:#F2F2F2;}
              .st16{fill:#0097ED;}
              .st17{fill:#333333;}
              .st18{font-family:'Roboto-Light';}
              .st19{font-size:27px;}
              .st20{font-size:19px;}
              .st21{fill:#1A1A1A;}
              .st22{font-family:'OpenSans';}
              .st23{font-size:13px;}
              .st24{fill:#E2E2E2;}
              .st25{fill:#E8F5FF;}
              .st26{fill:#5FB0E5;}
              .st27{fill:#D2E9F7;}
              .st28{fill:#0686D8;}
              .st29{fill:#FDF2C6;}
              .st30{fill:#F9BB30;}
              .st31{fill:#CCCCCC;}
              .st32{fill:#3A4237;}
              .st33{fill:#D19620;}
              .st34{font-family:'MyriadPro-Regular';}
              .st35{font-size:14px;}
            }
            &#etape_3{
              .st0{fill:#E7ECF0;}
              .st1{fill:#FFFFFF;}
              .st2{fill:#354351;}
              .st3{fill:#DBDBDC;}
              .st4{fill:#8A7542;}
              .st5{fill:#899097;}
              .st6{fill:#4B5864;}
              .st7{fill:#697A82;}
              .st8{fill:#425158;}
              .st9{fill:#FFBC2A;}
              .st10{fill:none;}
              .st11{font-family:'OpenSans-Semibold';}
              .st12{font-size:12px;}
              .st13{font-size:11px;}
              .st14{fill:none;stroke:#808080;stroke-miterlimit:10;}
              .st15{fill:#F2F2F2;}
              .st16{fill:#333333;}
              .st17{font-family:'Roboto-Light';}
              .st18{font-size:27px;}
              .st19{fill:#1A1A1A;}
              .st20{font-family:'OpenSans';}
              .st21{font-size:13px;}
              .st22{fill:#0097ED;}
              .st23{opacity:0.23;fill:#0097ED;}
              .st24{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
            }
            &#etape_4{
              .st0{fill:#E7ECF0;}
              .st1{fill:#FFFFFF;}
              .st2{fill:#FFBC2A;}
              .st3{opacity:0.2;fill:#0097ED;enable-background:new    ;}
              .st4{opacity:0.2;fill:none;stroke:#0097ED;stroke-miterlimit:10;enable-background:new    ;}
              .st5{fill:#DBDBDC;}
              .st6{fill:none;}
              .st7{fill:#333333;}
              .st8{font-family:'Roboto-Light';}
              .st9{font-size:27px;}
              .st10{font-family:'OpenSans-Semibold';}
              .st11{font-size:12px;}
              .st12{fill:#354351;}
              .st13{fill:#8A7542;}
              .st14{fill:#899097;}
              .st15{fill:#4B5864;}
              .st16{fill:#697A82;}
              .st17{fill:#425158;}
              .st18{font-size:11px;}
              .st19{fill:none;stroke:#808080;stroke-miterlimit:10;}
              .st20{font-family:'OpenSans';}
              .st21{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
              .st22{opacity:0.08;fill:#0097ED;enable-background:new    ;}
              .st23{fill:#CCCCCC;}
              .st24{fill:#FFFFFF;stroke:#E6E6E6;stroke-width:0.5;stroke-miterlimit:10;}
            }
            &#etape_5{
              .st0{fill:#E7ECF0;}
              .st1{fill:#FFFFFF;}
              .st2{opacity:0.27;fill:#354351;}
              .st3{opacity:0.2;fill:#0097ED;}
              .st4{fill:#FFBC2A;}
              .st5{fill:#DBDBDC;}
              .st6{fill:none;}
              .st7{fill:#333333;}
              .st8{font-family:'Roboto-Light';}
              .st9{font-size:27px;}
              .st10{font-family:'OpenSans-Semibold';}
              .st11{font-size:12px;}
              .st12{fill:#354351;}
              .st13{fill:#8A7542;}
              .st14{fill:#899097;}
              .st15{fill:#4B5864;}
              .st16{fill:#697A82;}
              .st17{fill:#425158;}
              .st18{font-size:11px;}
              .st19{fill:none;stroke:#808080;stroke-miterlimit:10;}
              .st20{font-family:'OpenSans';}
              .st21{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
              .st22{fill:#CCCCCC;}
              .st23{opacity:0.11;fill:#0097ED;}
              .st24{opacity:0.08;fill:#354351;}
              .st25{fill:#0097ED;}

              .st26{opacity:0.27;fill:none;stroke:#29ABE2;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:1,8;}
              .st27{fill:none;stroke:#E6E6E6;stroke-width:0.5;stroke-miterlimit:10;}
              .st28{fill:#E6E6E6;}
              .st29{fill:#4D4D4D;}
            }
            &#etape_6{
              .st0{fill:#E7ECF0;}
              .st1{fill:#FFFFFF;}
              .st2{opacity:0.2;fill:#0097ED;}
              .st3{fill:#0097ED;}
              .st4{fill:#DBDBDC;}
              .st5{fill:none;}
              .st6{fill:#333333;}
              .st7{font-family:'Roboto-Light';}
              .st8{font-size:27px;}
              .st9{font-size:16px;}
              .st10{font-family:'OpenSans-Semibold';}
              .st11{font-size:12px;}
              .st12{fill:#354351;}
              .st13{fill:#8A7542;}
              .st14{fill:#899097;}
              .st15{fill:#4B5864;}
              .st16{fill:#697A82;}
              .st17{fill:#425158;}
              .st18{fill:#FFBC2A;}
              .st19{font-size:11px;}
              .st20{fill:none;stroke:#808080;stroke-miterlimit:10;}
              .st21{font-family:'OpenSans';}
              .st22{fill:#CCCCCC;}
              .st23{opacity:0.08;fill:#354351;}
              .st24{opacity:0.26;fill:none;stroke:#0097ED;stroke-width:2;stroke-miterlimit:10;}
            }
            text{
              font-family: Roboto, "Helvetica Neue", Helvetica, Arial !important;
            }
          }
          p{
            line-height: normal;
          }
        }
      }
    }
    //Button
    .swiper-button-next, .swiper-button-prev, .swiper-button-valid{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      opacity: 1;
      border-radius: 50%;

      &.swiper-button-disabled{
        display: none;
      }
    }
    .swiper-button-next,.swiper-button-valid{
      right: -65px;
      background: $color-primary;
    }

    .swiper-button-valid{
      position: absolute;
      top: 45%;
      transform: translateY(-70%);
      animation: pulse 1500ms infinite;
      background-color: $color-primary!important;
      span{
        font-size:1.5rem;
        color: $white;
      }
      &:before{
        display : none;
      }
    }

    .swiper-button-prev{
      left: -65px;
      background: $white;

      &:before{
        color: $gray-600;
      }
    }

    //Pagination
    .swiper-pagination{
      bottom: -40px;
      .swiper-pagination-bullet{
        width: 10px;
        height: 10px;
        background: $gray-100;

        &.swiper-pagination-bullet-active{
          background: $color-primary;
        }
      }
    }
  }
}



.animation-target {
  -webkit-animation: animation 1000ms linear both;
  animation: animation 1000ms linear both;
}

/* Generated with Bounce.js. Edit at https://goo.gl/hS345i */

@-webkit-keyframes pulse {
  0% { -webkit-transform: matrix3d(0.7, 0, 0, 0, 0, 0.7, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.7, 0, 0, 0, 0, 0.7, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.4% { -webkit-transform: matrix3d(0.795, 0, 0, 0, 0, 0.822, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.795, 0, 0, 0, 0, 0.822, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { -webkit-transform: matrix3d(0.835, 0, 0, 0, 0, 0.88, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.835, 0, 0, 0, 0, 0.88, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.81% { -webkit-transform: matrix3d(0.898, 0, 0, 0, 0, 0.968, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.898, 0, 0, 0, 0, 0.968, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { -webkit-transform: matrix3d(0.965, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.965, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  10.21% { -webkit-transform: matrix3d(0.982, 0, 0, 0, 0, 1.068, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.982, 0, 0, 0, 0, 1.068, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.61% { -webkit-transform: matrix3d(1.037, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.037, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { -webkit-transform: matrix3d(1.042, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.042, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.52% { -webkit-transform: matrix3d(1.062, 0, 0, 0, 0, 1.072, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.062, 0, 0, 0, 0, 1.072, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { -webkit-transform: matrix3d(1.064, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.064, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  21.32% { -webkit-transform: matrix3d(1.059, 0, 0, 0, 0, 1.021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.059, 0, 0, 0, 0, 1.021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { -webkit-transform: matrix3d(1.045, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.045, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% { -webkit-transform: matrix3d(1.04, 0, 0, 0, 0, 0.981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.04, 0, 0, 0, 0, 0.981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% { -webkit-transform: matrix3d(1.019, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.019, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { -webkit-transform: matrix3d(1.014, 0, 0, 0, 0, 0.97, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.014, 0, 0, 0, 0, 0.97, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { -webkit-transform: matrix3d(0.994, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.994, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  36.74% { -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.991, 0, 0, 0, 0, 0.994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { -webkit-transform: matrix3d(0.988, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.988, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  44.44% { -webkit-transform: matrix3d(0.99, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { -webkit-transform: matrix3d(0.997, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.997, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  59.86% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  75.28% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  90.69% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}

@keyframes pulse {
  0% { -webkit-transform: matrix3d(0.7, 0, 0, 0, 0, 0.7, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.7, 0, 0, 0, 0, 0.7, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.4% { -webkit-transform: matrix3d(0.795, 0, 0, 0, 0, 0.822, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.795, 0, 0, 0, 0, 0.822, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { -webkit-transform: matrix3d(0.835, 0, 0, 0, 0, 0.88, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.835, 0, 0, 0, 0, 0.88, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.81% { -webkit-transform: matrix3d(0.898, 0, 0, 0, 0, 0.968, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.898, 0, 0, 0, 0, 0.968, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { -webkit-transform: matrix3d(0.965, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.965, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  10.21% { -webkit-transform: matrix3d(0.982, 0, 0, 0, 0, 1.068, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.982, 0, 0, 0, 0, 1.068, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.61% { -webkit-transform: matrix3d(1.037, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.037, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { -webkit-transform: matrix3d(1.042, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.042, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.52% { -webkit-transform: matrix3d(1.062, 0, 0, 0, 0, 1.072, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.062, 0, 0, 0, 0, 1.072, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { -webkit-transform: matrix3d(1.064, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.064, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  21.32% { -webkit-transform: matrix3d(1.059, 0, 0, 0, 0, 1.021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.059, 0, 0, 0, 0, 1.021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { -webkit-transform: matrix3d(1.045, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.045, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% { -webkit-transform: matrix3d(1.04, 0, 0, 0, 0, 0.981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.04, 0, 0, 0, 0, 0.981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% { -webkit-transform: matrix3d(1.019, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.019, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { -webkit-transform: matrix3d(1.014, 0, 0, 0, 0, 0.97, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.014, 0, 0, 0, 0, 0.97, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { -webkit-transform: matrix3d(0.994, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.994, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  36.74% { -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.991, 0, 0, 0, 0, 0.994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { -webkit-transform: matrix3d(0.988, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.988, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  44.44% { -webkit-transform: matrix3d(0.99, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.99, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { -webkit-transform: matrix3d(0.997, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.997, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  59.86% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  75.28% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  90.69% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}

