
body.error{
  background-repeat :no-repeat;
  -webkit-background-size : cover;
  background-size         : cover;
  background-position : center right;




  .topbar{
    display : flex;
    justify-content: flex-start;
    img{
      height :70%;
    }
  }

  .card.flow{
    margin-top :64px;
    position : relative;
    position : fixed;
    height :calc(100% - 64px);
    width :70%;
    top : 0;
    left : 0;

    @media (max-width: 1200px){
     /* position :static;*/
      background: linear-gradient(to bottom, #509beb 0%,#87b2f1 100%);
      svg{
        display : none;
      }
      width :100%;
      /*margin-top :50%;*/
    }
  }

  .content-error{
    position : relative;
    padding :1rem;
    padding-left :5rem;
    z-index :3;
    display : flex;
    height : 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction :column;

    .content{
      width :70%;
      /*justify-content: center;
     align-items: flex-start;
     flex-direction :column;*/

      .nav-item{
     /*   display : flex;
        justify-content: flex-end;*/
        @media (max-width: 750px){
          display : flex;
          justify-content: center;
          padding-top : 0!important;
        }
      }
    }
    .text{
      font-size :1rem;
    }

    h1{
      font-size :10rem!important;

      @media (max-width: 750px){
        font-size :4rem!important;
      }
      @media (max-width: 350px){
        font-size :3.5rem!important;
      }
    }
    h1,h2,h3{
      text-align : left!important
    }
  }
  #flow{
    position : absolute;
    z-index: 2;
    height :100%;
    width :100%;
    top :0;
    left : 0;
  }
}