
$height-theme-picture:130px;

@mixin rs-theme-picture($color,$name){
  .rs-theme-picture-#{$name}{
    overflow : hidden;
    position : relative;
    cursor : pointer;
    height :$height-theme-picture;
    width :100%;
    background-size: cover;
    background-position : center center;
    background-repeat : no-repeat;

    .btn{
      @include media-up(xl){
        opacity :0;
      }
      background-color : transparent!important;
      color : $white!important;
      border-color: $white!important;
    }

    .icon{
      width :100%;
      text-align : center;
      z-index: 4;
      opacity: 0;
      -webkit-transform: translate3d(0, 2000px, 0);
      transform: translate3d(0, 2000px, 0);
      position : absolute;
      left : 0;
      top : $height-theme-picture / 2.8;
      .fa,ti{
        text-align : center;
        font-size :2.5rem;
        color : $white;
      }
    }

    &:before{
      transition:all 0.35s ease;
      position : absolute;
      content : '';
      display : block;
      left : 0;
      top : 0;
      bottom : 0;
      right : 0;
      background : rgba($color-primary,0);
      z-index: 2;
    }

    &.theme-selected,&:hover{
      transition: all 0.35s ease;
      &:before{
        transition: all 0.35s ease;
        background : rgba($color-primary,0.5);
      }
      .btn{
        opacity : 1;
        transition: all 0.35s ease;
        /*transform: translate(-50%,-50%)scale(1);*/
        background-color : transparent!important;
        color : $white!important;
        border-color: $white!important;
      }


    }

    &.theme-selected{
      .btn{
        animation: bounceOutDown 0.6s ease;
        animation-fill-mode: forwards;
      }
      .icon{
        animation: bounceInUp 0.6s ease;
        animation-fill-mode: forwards;
      }
    }

    .btn-wrapper{
      width :100%;
      position : absolute;
      top :$height-theme-picture / 2.8;
      /* transform: translate(-50%,-50%)scale(0);*/
      z-index :3;
    }

  }
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}



@include rs-theme-picture($color-primary,primary);
@include rs-theme-picture($color-secondary,secondary);
@include rs-theme-picture($color-third,third);