@font-face
{
  font-family: 'gotham';
  font-weight: bold;
  font-style: normal;
  src : url('../../../dashboard/fonts/gotham/GothamRounded-Bold.woff') format('woff');
}

@font-face
{
  font-family: 'gotham';
  font-weight: normal;
  font-style: normal;
  src : url('../../../dashboard/fonts/gotham/GothamRounded-Book.woff') format('woff');
}

.card-pack{
  *{
    transition: all 0.5s ease-out;
  }
  border-radius : 10px !important;
  position : relative;
  &:hover .card-pack-hover-effect{
    opacity: 1;
  }
  .card-pack-hover-effect{
    opacity: 0;
    display: flex;
    height : 100%;
    width : 100%;
    z-index : 9;
    position : absolute;
    top : 0;
    left : 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color :rgba(255, 255, 255, .8);
    transition: opacity 200ms;

    div{
      display: flex;
      width: 80px;
      height : 80px;
      justify-content: center;
      align-items: center;
      border-radius : 50%;
      background-color : $color-third;

      i{
        color : #FFF;
        font-size: 40px;
      }
    }

    button.btn {
      border-width: 2px;
      background-color: rgba(255, 255, 255, 0.8);

      &:hover {
        background-color: $color-third;
      }
    }
  }
  .card-title{
    border-radius : 10px !important;
    height : 130px;
    background-image : url('../../../dashboard/images/store/forme-bleue.svg') !important;
    background-repeat: no-repeat;
    border-bottom: none;
    .quantity-pack{
      color : #FFFFFF;
      font-size : 40px;
      font-family: gotham;
      text-shadow: 5px 5px $color-primary;
      line-height: 1;

      @media screen and (min-width: 1600px) {
        font-size: 35px;
      }
    }
    .quantity-pack-custom{
      color : #FFFFFF;
      font-size : 30px;
      font-family: gotham;
      text-shadow: 5px 5px $color-primary;
    }
    .monney-pack{
      color : $color-primary;
      font-size : 20px;
      font-family: gotham;
    }
  }
  .card-body{
    img{
      margin-top : -50px;
      // max-height : 200px;
    }
  }
}

#slick-pack{
  .card-pack{
    overflow : hidden;
    margin-bottom: 15px;

    .card-title {
      margin-bottom: -80px;

      .quantity-pack {
        font-size : 30px;
        text-shadow: 2px 2px $color-primary;
      }
      .quantity-pack-custom {
        font-size : 20px;
        text-shadow: 2px 2px $color-primary;
      }
    }
    .card-body {
      
      img {
        margin-top : -35px;
      }
    }
  }
}