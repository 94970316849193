@media (max-width: 575.98px) {  
    .text-users-list-connection {
        font-size: 20px;
    }
}
@media (min-width: 1200px) {
    .picto-users-list-connection, img.user-avatar {
        display: none !important;
    }
    .text-users-list-connection {
        font-size: 15px;
    }
}
@media (min-width: 1575px) {
    .picto-users-list-connection, img.user-avatar {
        display: block !important;
    }
    .text-users-list-connection {
        font-size: 20px;
    }
}