.card-events{
  height: 100%;

  & > .row{
    height: 100%;

    .card-body-events{
      padding: 0;
      overflow: hidden;
      //height: 318px;
      height: 100%;
      border-radius: 5px;

      div[class*="card-events-info-"]{
        font-size: 0;
      }

      .skew-parent.card-events-info-top{
        background: linear-gradient(to right, #3899ec 0%, #7eb1f1 44%);
        transform: skewY(-6deg);
        transform-origin: top left;
        height: 60%;

        .skew-child{
          height: 100%;
          padding: 30px 15px 0;
          transform: skewY(6deg);
          font-size: 14px;
          color: $white;

          .container-flex{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            .city{
              position: relative;
              margin-top: 10px;
              text-align: center;

              &:after{
                content: "";
                position: absolute;
                bottom: -25px;
                left: 50%;
                transform: translateX(-50%);
                width: 2px;
                height: 20px;
                background: $color-third;
              }
            }
            .info-left{
              margin-top: 30px;
              max-width: 55%;
              [class^="icon-"]{
                font-family: 'rs-weather'!important;
                margin-right: 10px;
                font-size: 4.2em;
              }
              .temp{
                display: inline-block;
                font-size: 2.5em;
                font-weight: 700;
              }
              .weather{
                margin-top: 5px;
              }
            }
            .info-right{
              max-width: 45%;
              margin-top: 45px;
              text-align: right;
              .day{
                font-size: .95em;
                @media (min-width: 1380px){                  
                  font-size: 1.15em;
                }
                font-weight: 700;
              }
            }
          }
        }
      }
      //CTA
      .card-events-info-bottom{
        height: 40%;

        .container-flex{
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          height: 100%;

          & > div{
            position: relative;
            display: inline-block;
            padding-top: 5px;
            width: 110px;
            font-size: 13px;
            text-align: center;
            color: $color-primary;
            font-weight: 700;

            // pointer sur les items active
            cursor: pointer;

            &.empty{
              opacity: 0.5;
              pointer-events: none;
              // default sur les items empty
              cursor: default;
            }

            .notif{
              position: absolute;
              top:0;
              right: 10px;
              width: 16px;
              height: 16px;
              background: $color-third;
              border-radius: 50%;
              color: $white;
              font-size: 0.7em;
              text-align: center;
              line-height: 16px;
            }
            a:hover{
              color: lighten($color-primary,8%);
            }

            .fa{
              font-size: 2.8em;
              border: 1px solid $color-primary;
              padding: 16px;
              border-radius: 50%;
              @include btn-outline($color-primary,8%);
            }
          }
        }

      }

    }
  }
}
.no-meteo{
  .city:after{
    display:none;
  }
  .info{
    .info-left{
      max-width: inherit !important;
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 20px;
    }
  }
}

