
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($color-primary, 0.4);
    box-shadow: 0 0 0 0 rgba($color-primary, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba($color-primary, 0);
    box-shadow: 0 0 0 10px rgba($color-primary, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba($color-primary, 0);
    box-shadow: 0 0 0 0 rgba($color-primary, 0);
  }
}

.pulse{
  animation: pulse 1s ease infinite;
}