.container-tooltip{
  padding: 10px 10px 10px 20px;

  ul{
    padding: 0;
    margin: 0;

    li{
      margin-bottom: 10px;
      text-align: left;
    }
  }
}