.shepherd-active {
  .shepherd-target.shepherd-enabled{
    position: inherit;
  }
}

.shepherd-step{
  &.shepherd-element.shepherd-theme-arrows-plain-buttons{
    border-radius: 10px;
    border: 0;

    .shepherd-content{
      border: 0;
      text-align: center;

      @include media-up(lg){
        min-width: 540px;
      }
      header{
        padding: 0;
        background: #e8f5ff;

        .shepherd-cancel-link{
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 1.2em;
          color: $color-primary;
          opacity: 1;

          &:after{
            content: "✕";
            display: block;
          }
        }
        .shepherd-title{
          width: 100%;
          font-weight: 700;

          position: relative;
          float: none;
          min-height: 75px;
          overflow: hidden;

          display: flex;
          align-items: center;

          img{
            display: block;
            width: 22%;
            position: absolute;
            left: -20px;
            top: -12px;
            opacity: 0.54;
          }

          & > span{
            z-index: 1;
            display: inline-block;
            padding: 0 55px;
            width: 100%;

            span{
              display: inline-block;
              margin-bottom: 5px;
              color: $color-primary;
              font-size: 1.25em;
            }
          }

        }
        .shepherd-cancel-link {
          z-index: 2;
        }
      }
      .shepherd-text{
        padding: 40px 60px;
      }
      footer{
        ul.shepherd-buttons{
          text-align: center;
          li{
            display: inline-block !important;
            margin-bottom: 10px !important;

            .shepherd-button{
              white-space: normal;
            }

          }
        }
      }
    }
  }
}

body {
  &[data-shepherd-step="gestion-user-main"],
  &[data-shepherd-step="gestion-groupe-user-main"],
  &[data-shepherd-step="home-main"],
  &[data-shepherd-step="orders-list-main"],
  &[data-shepherd-step="com-mail-list-main"],
  &[data-shepherd-step="news-list-main"],
  &[data-shepherd-step="points-list-main"],
  &[data-shepherd-step="param-website-main"]  {
    .sidebar {
      z-index: 1000;
    }
  }
  &[data-shepherd-step="home-top-bar"] {
    .topbar {
      z-index: 1000;
    }
  }
  &[data-shepherd-step="points-list-second"] {
    #tour-points-list {
      .col-sm-12 {
        z-index: 1111;
        table {
          background-color: #fff;
        }
      }
    } 
  }
  &[data-shepherd-step="news-list-third"] {
    #tour-news-list {
      .col-sm-12 {
        z-index: 1000;
        table {
          background-color: #fff;
        }
      }
    } 
  }
  &[data-shepherd-step="points-list-main"] {
    .frame-form-white-bizz {
      z-index: 1000;
    }    
  }
}
