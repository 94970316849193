.preview-media.preview:not(:empty) {
    width: 400px;
    padding: 0.25rem;
    border: 1px solid #f3f3f3;

    .fullWidthPreview & {    
      img { margin: 0 auto; }
      width: 100%;
      .teaser.teaser-simple {
        width: 100%;
      }
    }

    .teaser-simple{
      display: flex;
      align-items: center;
      /*justify-content: center;*/
      height: 300px;
      background: $gray-100;
    }
    .teaser-simple--center{
      text-align: center;
      height: 300px;
      background: $gray-100;
    }

    img{
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      min-height:inherit;
    }

}

//Icon PDF une fois le fichier charge
.teaser-icon-pdf{
  width: 250px;
  height: 110px;
  padding: 0.25rem;
  border: 1px solid #f3f3f3;
  background: none;

  .icon-pdf{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #f8f9fa;
  }
  figcaption{
 /*   background: rgba(55, 67, 81, 0.7);*/

    .btn{
      background: none;
      border: 0;
      color:white;
    }
  }
}


.node-content-arbo {
  &:not(.node-disabled){
    &.actif{
      background: $color-pale-primary;
    }
  }
}
.expand-icon-content {
  width: 150px;

  &:hover {
    cursor: pointer;
    background: $gray-100;
    /*background: #f5f6f7;*/
  }

  p{
    line-height: normal;
  }
}

//media patch

#gestionnaire-image{


  .content-hide-ie{
    overflow : hidden;
    height : 100%;
    width :100%;
    display : flex;
    align-items: center;
    justify-content: center;
  }

  .card-body{
    overflow-y: auto;
  }
  #area{
    width :100%;
  }
  .img-min{
    transition: all 0.35s ease;
  }

  .teaser{
    overflow: inherit;
    border : 5px solid white;

    .content-icon{
      position : absolute;
      opacity: 0;
      transition: all 0.35s ease-in-out;

      .fa{
        transition: all 0.35s ease-in-out;
   /*     transform:translateY(100%);*/
        font-size :1rem;
      }
    }
  }
  .content-icon{
    text-align : center;


    .fa{
      pointer-events: none;
      margin-left :0.5rem;
      margin-right :0.5rem;
      background : $color-primary;
      color : $white;
      width :35px;
      height :35px;
      opacity: 0;
      /*transition: all 0s;*/
      line-height: 35px;
      border-radius: 50%;
      transition: all 0.35s ease;
      &:hover{
        transition: all 0.35s ease;
        background : darken($color-primary,15%);

      }
    }

  }

  .teaser.item-check{
    .content-icon{
      transition: all 0.35s ease-in-out;
      opacity: 1;
    }
  }
  .top-buttons{
   /* position : relative;
    z-index :9999;*/
    .teaser.item-check{
      .content-icon{
        position: absolute;
        top: 0;
        z-index :999;
        left : 0;
        transform: translateY(-130%);
        width :100%;
      }

      .fa{
        pointer-events: initial;
        opacity : 0;
        animation: swooshtop 0.6s ease-in-out;
        animation-fill-mode: forwards;
        /*     transition: all 0.35s ease-in-out;
        transform:translateY(0%);*/

        &:nth-of-type(1){

        }
        &:nth-of-type(2){
          animation-delay: 0.1s;
        }
        &:nth-of-type(3){
          animation-delay: 0.2s;

        }
        &:nth-of-type(4){
          animation-delay: 0.3s;

        }
        &:nth-of-type(5){
          animation-delay: 0.4s;

        }
      }
    }
  }
  .bottom-buttons{
    .teaser.item-check{
      .content-icon{
        z-index: 999;
        position: absolute;
        bottom: 0;
        left : 0;
        transform: translateY(130%);
        width :100%;
      }

      .fa{
        pointer-events: initial;
        opacity : 0;
        animation: swooshbottom 0.6s ease-in-out;
        animation-fill-mode: forwards;
        /*     transition: all 0.35s ease-in-out;
        transform:translateY(0%);*/

        &:nth-of-type(1){

        }
        &:nth-of-type(2){
          animation-delay: 0.1s;
        }
        &:nth-of-type(3){
          animation-delay: 0.2s;

        }
        &:nth-of-type(4){
          animation-delay: 0.3s;

        }
        &:nth-of-type(5){
          animation-delay: 0.4s;

        }
      }
    }
  }
}







@keyframes swooshtop {
  0% { opacity:0;-webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 300, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 300, 0, 1); }
  1.3% { -webkit-transform: matrix3d(1.104, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 237.02, 0, 1); transform: matrix3d(1.104, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 237.02, 0, 1); }
  2.55% { -webkit-transform: matrix3d(1.127, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 182.798, 0, 1); transform: matrix3d(1.127, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 182.798, 0, 1); }
  4.1% { -webkit-transform: matrix3d(1.108, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 125.912, 0, 1); transform: matrix3d(1.108, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 125.912, 0, 1); }
  5.71% { -webkit-transform: matrix3d(1.073, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 79.596, 0, 1); transform: matrix3d(1.073, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 79.596, 0, 1); }
  8.11% { -webkit-transform: matrix3d(1.029, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 31.647, 0, 1); transform: matrix3d(1.029, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 31.647, 0, 1); }
  8.81% { -webkit-transform: matrix3d(1.021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 21.84, 0, 1); transform: matrix3d(1.021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 21.84, 0, 1); }
  11.96% { -webkit-transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -4.825, 0, 1); transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -4.825, 0, 1); }
  12.11% { -webkit-transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -5.53, 0, 1); transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -5.53, 0, 1); }
  15.07% { -webkit-transform: matrix3d(0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -12.662, 0, 1); transform: matrix3d(0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -12.662, 0, 1); }
  16.12% { -webkit-transform: matrix3d(0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -13.007, 0, 1); transform: matrix3d(0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -13.007, 0, 1); }
  27.23% { opacity:1; -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -2.352, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -2.352, 0, 1); }
  27.58% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -2.121, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -2.121, 0, 1); }
  38.34% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.311, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.311, 0, 1); }
  40.09% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.291, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.291, 0, 1); }
  50% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.048, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.048, 0, 1); }
  60.56% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.007, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.007, 0, 1); }
  82.78% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { opacity:1;-webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}

@keyframes swooshbottom {
  0% { opacity:0;-webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -300, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -300, 0, 1); }
  1.3% { -webkit-transform: matrix3d(1.207, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -237.02, 0, 1); transform: matrix3d(1.207, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -237.02, 0, 1); }
  2.55% { -webkit-transform: matrix3d(1.254, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -182.798, 0, 1); transform: matrix3d(1.254, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -182.798, 0, 1); }
  4.1% { -webkit-transform: matrix3d(1.216, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -125.912, 0, 1); transform: matrix3d(1.216, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -125.912, 0, 1); }
  5.71% { -webkit-transform: matrix3d(1.146, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -79.596, 0, 1); transform: matrix3d(1.146, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -79.596, 0, 1); }
  8.11% { -webkit-transform: matrix3d(1.059, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -31.647, 0, 1); transform: matrix3d(1.059, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -31.647, 0, 1); }
  8.81% { -webkit-transform: matrix3d(1.041, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -21.84, 0, 1); transform: matrix3d(1.041, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -21.84, 0, 1); }
  11.96% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 4.825, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 4.825, 0, 1); }
  12.11% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 5.53, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 5.53, 0, 1); }
  15.07% { -webkit-transform: matrix3d(0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 12.662, 0, 1); transform: matrix3d(0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 12.662, 0, 1); }
  16.12% { -webkit-transform: matrix3d(0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 13.007, 0, 1); transform: matrix3d(0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 13.007, 0, 1); }
  27.23% { opacity:1;-webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 2.352, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 2.352, 0, 1); }
  27.58% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 2.121, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 2.121, 0, 1); }
  38.34% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.311, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.311, 0, 1); }
  40.09% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.291, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.291, 0, 1); }
  50% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.048, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.048, 0, 1); }
  60.56% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.007, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.007, 0, 1); }
  82.78% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {opacity: 1; -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}


#content-child-folder{
  width :100%;
  & > .grid{
    margin : 0!important;
    width : 100%;
  }
}



// toDO ATTENTION rules pour modal global
.modal-dialog{
  .container-img{
    img{
      max-width :750px!important;
      max-height :750px!important;
    }
  }
}


/////////////////////////////
////// Crop patch IE
////////////////////////////
.modal-dialog{
  .modal-body.modal-body-crop{
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : center;
  }

 .cropper-bg img{
   width :100%;
  }
}
