@mixin frame-form($content,$color,$name) {
  .frame-form-#{$name} {
    height   : 160px;
    position : relative;
    &:before {
      font-family : 'rs';
      content     : '#{$content}';
      display     : inline-block;
      position    : absolute;
      top         : 50%;
      left        : 50%;
      transform   : translateX(-50%);
      color       : $color-secondary;
      font-size   : 9rem;
      z-index     : 1;
      text-shadow : $color 3px 0px 0px, $color 2.83487px 0.981584px 0px, $color 2.35766px 1.85511px 0px, $color 1.62091px 2.52441px 0px, $color 0.705713px 2.91581px 0px, $color -0.287171px 2.98622px 0px, $color -1.24844px 2.72789px 0px, $color -2.07227px 2.16926px 0px, $color -2.66798px 1.37182px 0px, $color -2.96998px 0.42336px 0px, $color -2.94502px -0.571704px 0px, $color -2.59586px -1.50383px 0px, $color -1.96093px -2.27041px 0px, $color -1.11013px -2.78704px 0px, $color -0.137119px -2.99686px 0px, $color 0.850987px -2.87677px 0px, $color 1.74541px -2.43999px 0px, $color 2.44769px -1.73459px 0px, $color 2.88051px -0.838247px 0px;
    }
    p {
      position    : absolute;
      z-index     : 3;
      top         : 50%;
      left        : 50%;
      max-width   : 125px;
      overflow    : hidden;
      transform   : translateX(-50%);
      font-size   : 1.3rem;
      font-weight : 600;
    }

  }
}

/*  content: "\e90a";*/
@include frame-form("\e90a", $white, white-bizz);
@include frame-form("\e90a", $color-third, third-bizz);